
  import { Component, Vue } from 'vue-property-decorator';
  import { Sport, SportNotStrict } from '@/types/entities';
  import { UPDATE_SPORTS } from '@/store/sports';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import EditSportModal from '@/components/EditSportModal.vue';

  @Component({
    components: { EditSportModal, HipsterCheckbox }
  })
  export default class SportsListTable extends Vue {
    public sportToEdit: SportNotStrict | Sport = {};

    public updateSport(id: number, params: SportNotStrict) {
      this.$store.dispatch(UPDATE_SPORTS, { id, params });
    }

    public onEditSport(sport: Sport) {
      this.sportToEdit = sport;
      this.$modal.show('edit-sport-modal');
    }
  }
