
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Sport, SportNotStrict } from '@/types/entities';
import InspiniaIbox from '@/components/InspiniaIbox.vue';
import HipsterInput from '@/components/HipsterInput.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { UPDATE_SPORTS } from '@/store/sports';
import HipsterCheckbox from '@/components/HipsterCheckbox.vue';

@Component({
  components: { HipsterInput, InspiniaIbox, HipsterCheckbox },
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        display_name: { required }
      }
    };
  }
})
export default class EditSportModal extends Vue {
  @Prop({
    default: () => ({})
  }) sport: Sport | SportNotStrict;

  public formData: SportNotStrict = {
    display_name: ''
  };

  @Watch('sport')
  public sportChanged(): void {
    this.formData = { ...this.sport };
  }

  public onSubmit(): void {
    this.$v.$touch();

    if (this.$v.$error) {
      return;
    }

    this.$store.dispatch(UPDATE_SPORTS, { id: this.sport.id, params: this.formData })
        .then(() => this.$modal.hide('edit-sport-modal'));
  }
}
