
  import { Component, Vue } from 'vue-property-decorator';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import SportsListTable from '@/components/SportsListTable.vue';

  import { SearchSportsParams } from '@/types/search';
  import { FETCH_SPORTS } from '@/store/sports';
  import { RawLocation } from 'vue-router';

  @Component({
    components: { HipsterInput, HipsterSelector, SportsListTable }
  })
  export default class SportsList extends Vue {
    public filters: SearchSportsParams = {
      per_page: 15,
      page: 1,
      query: '',
      is_supported: null
    };

    public statuses = [
      { name: 'No', value: 0 },
      { name: 'Yes', value: 1 }
    ];

    public selectedStatus = this.statuses[0];

    created() {
      this.search(this.$route.query as SearchSportsParams);
    }

    get sports() {
      return this.$store.state.sports.itemsList.data;
    }

    public search(filter: SearchSportsParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.filters = Object.assign(this.filters, this.$route.query, filter);

      this.$router.push({ query: this.filters } as RawLocation);

      this.$store.dispatch(FETCH_SPORTS, this.filters);
    }
  }
